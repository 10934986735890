<template lang="pug">
  .sources-page
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        indeterminate
        size="80" 
        color="#1438F5"
      )
    .header
      h1.nio-h1.text-primary-darker Sources
      NioButton(
        :disabled="!loading && !canCreateBucketSource"
        normal-primary
        @click="newSource"
      ) New Source
    NioAlert(
      :dismissable="true"
      :visible="!loading && !canCreateBucketSource && showBucketLimitWarning"
      message="Companies are currently limited to a single managed S3 bucket."
      warning
      @dismiss="showBucketLimitWarning = false"
    )
    .no-data-streams(
      v-if="sources && sources.length === 0"
    )
      NioIconFramer(icon-name="display-sources")
      h3.nio-h3.text-primary-darker You have no sources
      NioButton(
        normal-secondary
        @click="seeHow"
      ) Learn about sources
    .data-streams(
      v-else
    )
      NioSlatTable(
        v-if="columns && sources"
        :items="sources"
        :columns="columns"
        :footer-actions="false"
        :initial-items-per-page="100"
        action="expand"
      )
        template(v-slot:item-expanded="slotProps")
          .source-review
            template(v-if="slotProps.item.type === 'bucket'")
              h2.nio-h2.text-primary-darker.review-section-header Access Config
              .bucket-auth
                template(v-if="slotProps.item.auth.type === 'bucket_policy'")
                  .filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker AWS Account ID
                      .filter-description.nio-p.text-primary-dark The AWS Account ID provided at bucket creation that has access to the role.
                    .filter-value
                      NioTextField(
                        v-model="slotProps.item.auth.account_id"
                        label="Role AWS Account ID"
                        disabled
                      )
                  .filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker Bucket Name
                    .filter-value
                      NioTextarea(
                        v-model="slotProps.item.name"
                        label="Bucket Name"
                        rows="3"
                        disabled
                      )
                      NioCopyToClipboard(
                        :text="slotProps.item.name"
                      )
                  .filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker Access Type
                    .filter-value
                      .filter-description.nio-p.text-primary-dark Your bucket's policy grants access to any principal within your AWS account.
                template(v-if="slotProps.item.auth.type === 'role'")
                  .filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker AWS Account ID
                      .filter-description.nio-p.text-primary-dark The AWS Account ID provided at bucket creation that has access to the role.
                    .filter-value
                      NioTextField(
                        v-model="slotProps.item.auth.account_id" 
                        label="Role AWS Account ID" 
                        disabled
                      )
                  .filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker Bucket Name
                    .filter-value
                      NioTextarea(
                        v-model="slotProps.item.name" 
                        label="Bucket Name" 
                        rows="3"
                        disabled
                      )
                      NioCopyToClipboard(
                        :text="slotProps.item.name"
                      )
                  .filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker Role ARN
                    .filter-value
                      NioTextarea(
                        v-model="slotProps.item.auth.role" 
                        rows="3"
                        label="Role ARN" 
                        disabled
                      )
                      NioCopyToClipboard(
                        :text="slotProps.item.auth.role"
                      )
                  .filter(v-if="slotProps.item.auth.external_id")
                    .title-description
                      .filter-title.nio-h4.text-primary-darker External ID
                      .filter-description.nio-p.text-primary-dark
                        p The external ID that must be provided when assuming the role.&nbsp;
                          a(href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html?icmpid=docs_iam_console" target="_blank") Learn more.
                    .filter-value
                      NioTextField(
                        v-model="slotProps.item.auth.external_id" 
                        label="External ID" 
                        disabled
                      )
                template(v-if="slotProps.item.auth.type === 'user'")
                  .filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker User ARN
                    .filter-value
                      NioTextField(
                        v-model="slotProps.item.auth.user" 
                        label="User ARN" 
                        disabled
                      )
                      NioCopyToClipboard(
                        :text="slotProps.item.auth.user"
                      )
                  .filter
                    .title-description
                      .filter-title.nio-h4.text-primary-darker AWS Access Key ID
                    .filter-value
                      NioTextField(
                        v-model="slotProps.item.auth.access_key_id" 
                        label="AWS Access Key ID" 
                        disabled
                      )
                      NioCopyToClipboard(
                        :text="slotProps.item.auth.access_key_id"  
                      )
      NioAlert(
        v-if="columns && sources && Boolean(bucketAuth)"
        :visible="columns && sources && Boolean(bucketAuth)"
        :dismissable="false"
        warning
      ).account-config-alert
        .filter.account-config-message
          .title-description
            .filter-title.nio-p.text-primary-dark 
              <p>In order to access your managed bucket, you will need to configure your AWS account to allow access. In order to do this, you will need to add this policy to your account.</p>
              <p>If you are unsure how to add a new policy, instructions can be found by clicking the button below.</p>
            NioButton(
              normal-secondary
              @click="managedBucketsMoreInfo"
            ) More info
          .filter-value
            .policy-text.text-primary-dark.nio-p-small {{ JSON.stringify(accountConfig, null, 2) }}
            NioCopyToClipboard(:text="JSON.stringify(accountConfig)")
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import moment from 'moment'

export default {
  data: () => ({
    columns: null,
    loading: true,
    showBucketLimitWarning: true,
    sources: null
  }),
  computed: {
    canCreateBucketSource() {
      // Companies are limited to a single bucket for now.
      return this.sources !== null && !this.sources.map(source => source.type).includes('bucket')
    },
    bucketAuth() {
      return this.sources?.length > 0 && this.sources[0].auth ? this.sources[0].auth.role : null
    },
    accountConfig() {
      return this.bucketAuth ? {
        Version: "2012-10-17",
        Statement: [
          {
              Sid: "AssumeNarrativeBucketAccessRole",
              Effect: "Allow",
              Action: "sts:AssumeRole",
              Resource: this.bucketAuth

          }
        ]
      } : null
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    openApiInit() {
      this.getSources()
    },
    newDataStream() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'new'
      },"*")
    },
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + '...'
      }
      return str
    },
    formatDate(date) {
      return `${date.getFullYear()}`
    },
    getSources() {
    // NB: only S3 bucket sources are supported, so the below only consults /resources/buckets and coerces it into a
    // "source".
      this.$nioOpenApi.get('/resources/buckets')
        .then(res => {
          this.sources = res.data.records.map((bucket) => {
            return {
              type: 'bucket',
              ...bucket
            };
          });
          this.mkTableColumns()
          this.loading = false
        })
    },
    newSource() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'sources/new'
      },"*")
    },
    mkSourceId(item) {
      `#${item.id}`
    },
    mkSourceCreated(item) {
      return moment(item.created_at).format('MMM D, YYYY')
    },
    mkSourceName(item) {
      return item.name
    },
    mkSourceType(item) {
      return `${item.type}`
    },
    mkTableColumns() {
      this.columns = [
        {
          name: 'slat',
          props: {
            title: 'name',
            computed: this.mkSourceName,
            subtitle: this.mkSourceId
          }
        },
        {
          name: 'type',
          label: 'Type',
          computed: this.mkSourceType,
          addItemAsClass: true
        },
        {
          name: "created",
          label: "Created",
          computed: this.mkSourceCreated
        }
      ]
    },
    seeHow() {
      // todo(mbabic) real link to kb.narrative.io about ingestion sources
      window.open('https://kb.narrative.io/dataset-manager', '_blank')
    },
    managedBucketsMoreInfo() {
      window.open('https://kb.narrative.io/how-do-i-set-up-a-managed-bucket', '_blank')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.sources-page
  background-color: $c-white
  padding: 1.5rem
  .header
    display: flex
    justify-content: flex-start
    align-items: flex-end
    position: relative
    margin-bottom: 1.5rem
    height: 2.25rem
    h1
      line-height: 1.75rem
    .nio-button
      position: absolute
      right: 0rem
  .no-data-streams, .free-version-footer
    padding: 9.6875rem 1.5rem 11.1875rem 1.5rem
    background-color: $c-canvas
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    display: flex
    flex-direction: column
    align-items: center
    .nio-icon-framer
      margin-bottom: 1rem
    h3
      margin-bottom: 0.5rem
    p
      margin-bottom: 2.5rem
  .data-streams.free-version
    padding: 0rem !important
    ::v-deep .nio-slat-title
      max-width: 31.25rem
      white-space: pre-wrap !important
    ::v-deep .header-selected
      display: none
    ::v-deep .nio-slat-table-actions
      padding: 0rem !important
    ::v-deep .nio-slat-table-pagination
      display: none
    .free-version-footer
      border: none
      border-radius: 0rem
      width: 100
    ::v-deep .nio-slat-content
      width: 100%
    ::v-deep .nio-slat-action
      display: none
    ::v-deep td:not(.expanded-row)
      padding-top: 1.5rem
      padding-bottom: 1.5rem
    ::v-deep .expanded-row
      border-bottom: 0.0625rem solid $c-primary-lighter
      position: relative
      padding: 2rem 1.5rem 2.5rem 1.5rem
      background-color: $c-canvas
      .empty
        opacity: 0.7
      .display-row
        margin-bottom: 1.5rem
        &:not(.display-table)
          display: flex
          flex-direction: column
          align-items: flex-start
          .nio-h7
            margin-bottom: 0.25rem
        &.display-table
          display: flex
          justify-content: space-between
          align-items: flex-start
          .display-column
            display: flex
            flex-direction: column
            align-items: flex-start
            flex-grow: 2
            margin-right: 1.5rem
            &.description
              max-width: 22.5rem
              .nio-h7, .nio-p
                max-width: 100%
        .pills
          display: flex
          flex-wrap: wrap
          .nio-pill
            margin-right: 0.25rem
  ::v-deep .column-status.value-Stopped .nio-table-value
    color: $c-error

  .data-streams-actions
    display: flex
    justify-content: flex-end
    padding: 1rem 2.75rem
    background-color: $c-canvas
    border-top: 0.0625rem solid $c-primary-lighter
  .c-btn
    margin: 1rem 2rem

  .dataset-manager
    padding: 1rem
    .filter
      background-color: white
      border: 0.0625rem solid $c-primary-lighter
      border-radius: 0.75rem
  .source-review
    background: $c-canvas
    padding: 2rem 2rem 2rem 2rem

    .filter
      display: grid
      grid-template-columns: 1fr 2fr
      grid-gap: 1rem
      align-items: center
      width: 100%
      border: 0.0625rem solid $c-primary-lighter
      border-bottom: 0rem
      padding: 1rem
      .title-description
        align-self: flex-start
      &:first-child
        border-radius: 0.75rem 0.75rem 0 0

      &:last-child
        border-radius: 0 0 0.75rem 0.75rem
        border-bottom: 0.0625rem solid $c-primary-lighter

      .filter-value
        width: 100%
        position: relative
        .nio-copy-to-clipboard
          position: absolute
          right: 1rem
          top: 0.5rem
        .nio-text-field
          margin-bottom: 0
      .nio-textarea 
        ::v-deep .v-input__slot
          background-color: $c-canvas
        ::v-deep textarea
          padding-right: 4.5rem
  .review-section-header
    margin-bottom: 1rem
    width: 100%
  .account-config-alert
    margin-top: 1.5rem
    border-color: $c-primary-lighter !important
    border-width: 0.0625rem !important
    padding: 0rem
    .account-config-message
      +nio-filter-header
      padding: 1.5rem
      .title-description
        padding-right: 2rem
        .nio-button
          margin-top: 1.5rem
      .filter-value
        position: relative
        .policy-text
          padding: 1rem
          background-color: $c-canvas
          white-space: break-spaces
          width: 100%
          border: 0.0625rem solid $c-primary-lighter
          border-radius: 0.75rem
        .nio-copy-to-clipboard
          position: absolute
          right: 1rem
          top: 0rem
</style>